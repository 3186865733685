.layout {
    width: 100%;
    .content {
        padding: 35px;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}