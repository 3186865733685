.home {
    width: 100%;
    max-width: 800px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .header {
        font-size: 35px;
    }
    textarea {
        width: 100%;
        height: 50%;
        border: 1px solid $secondary;
        border-radius: 7px;
        padding: 17.5px;
        resize: none;
        &:hover, &:focus {
            border-color: $accent;
        }
    }
    .actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        button {
            padding: 17.5px;
            width: 30%;
            background-color: $accent;
            color: $primary;
            border: 1px solid $accent;
            border-radius: 7px;
            &:hover {
                opacity: .7;
            }
        }
    }
}